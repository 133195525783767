/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({lang, title, categoryHandle, statNameHandle, dateInfo }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  const titleText = `Tracking Gun Violence Losses Under Biden`
  const descriptionText = `As gun deaths and injuries reach crisis levels across the country and congressional Republicans continue to block lifesaving legislation, President Joe Biden must lead by doing everything and anything he can to prioritize gun violence reduction.`
  const metaDescription = descriptionText || site.siteMetadata.description
  const defaultTitle = site.siteMetadata.title


   //formatted
   const dateString = JSON.stringify(`${dateInfo}`);
   dateString.split(" ", 1).join("").replaceAll('/', '-')
   const year = dateString.slice(7, 11);
   const month = dateString.slice(4, 6);
   const day = dateString.slice(1,3);
   const formattedDate = `${year}-${day}-${month}`;

  const cardUrl = `https://shock-market-api.s3.amazonaws.com/twitter-cards-${categoryHandle}-${statNameHandle}${formattedDate}.jpeg`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
    >
      {/* redirecting the page with a metatag would not work passing in as a prop to the helmet directly,
      originally worked having other meta tags passed and just refresh as a child to the helmet but stopped working */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={cardUrl} />
      <meta name="twitter:creator" content={metaDescription} />
      <meta name="twitter:title" content={titleText} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="desscription" content={metaDescription}/>
      <meta property="og:image" content={cardUrl} />
      <meta property="og:title" content={titleText}/>
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta http-equiv="refresh" content="1;url='https://shockmarket.org'" />
      <script type="application/ld+json">{`
        {
            "@context": "http://schema.org"
        }
    `}</script>

        {/* inline script elements */}
 
      {/* noscript elements */}
      <noscript>{`
       <img height="1" width="1" style="display:none"
       src="https://www.facebook.com/tr?id=242320864749232&ev=PageView&noscript=1"
       />
    `}</noscript>
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default Seo
