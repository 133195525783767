import React from "react";
import Seo from "../components/seo";
import makeHandle from "../helpers/makeHandle";

const Redirect = ({ pageContext }) => {
  const categoryHandle = makeHandle(pageContext.category);
  const statNameHandle = makeHandle(pageContext.stat.stat_name);
  const lastUpdated = pageContext.lastUpdated;
  return (
    <Seo 
      title={`Shock Market`}
      lang='en'
      categoryHandle={categoryHandle}
      statNameHandle={statNameHandle}
      dateInfo = {lastUpdated}
    />
  );
};

export default Redirect;